import DroneNav from './drone';
import NatureNav from './nature';
import AboutUsNav from './about-us';
export default {
  name: 'HelloWorld',
  components: {
    DroneNav,
    NatureNav,
    AboutUsNav
  },
  props: {
    msg: String
  },
  watch: {
    $route(to, from) {
      // 重复点击主导航menu, 判断路由中是否携带了query，则把之前的query带上
      if (Object.keys(to.query).length === 0 && from.name === to.name && Object.keys(from.query).length > 0) {
        this.$router.replace({
          query: from.query
        });
      }
      // 鼠标移入其他menu未进行点击跳转，则把navFlag恢复
      this.resetNavFlag(to);
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  data() {
    return {
      isEnter: false /**控制顶部导航栏鼠标移入移出 */,
      navbarHighlight: false,
      //滚动条超过导航栏高度始终高亮
      activeName: '',
      navFlag: 0,
      mainMenu: this.$router.options.routes,
      routeQuery: this.$route.query
    };
  },
  computed: {
    logoSrc: {
      get() {
        return this.GLOBAL.OSS_BASE_URL + '/home/' + (this.navbarHighlight || this.isEnter ? 'yjlogo_blue.png' : 'yjlogo_write.png');
      }
    }
  },
  methods: {
    /** 点击了解更多 获取 index */
    getNatureMenuIndexs(data) {
      this.$emit('setMenuIndex', data); /// 菜单拦index 传入page页
    },

    handleScroll() {
      if (!this.navbarHighlight && window.pageYOffset > this.$refs.navbar.offsetHeight) {
        this.navbarHighlight = true;
      } else if (window.pageYOffset <= this.$refs.navbar.offsetHeight) {
        this.navbarHighlight = false;
      }
    },
    headerHover() {
      this.isEnter = !this.isEnter;
      if (!this.isEnter) {
        this.resetNavFlag(this.$route);
      }
    },
    mouseOver(flag) {
      this.navFlag = flag;
      this.$emit('navFlag', flag);
    },
    resetNavFlag(to) {
      if (to.path === '/') {
        this.navFlag = 0;
        return;
      }
      let matchIdx = this.mainMenu.findIndex(item => {
        return to.matched[0].path === item.path;
      });
      if (matchIdx > -1) {
        this.navFlag = matchIdx;
      }
    },
    // 关闭二级导航
    onClose() {
      this.isEnter = false;
      this.resetNavFlag(this.$route);
    }
  }
};