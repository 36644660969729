/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./about-us.vue?vue&type=template&id=fffac4b4&scoped=true&"
import script from "./about-us.vue?vue&type=script&lang=js&"
export * from "./about-us.vue?vue&type=script&lang=js&"
import style0 from "./about-us.vue?vue&type=style&index=0&id=fffac4b4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fffac4b4",
  null
  
)

export default component.exports