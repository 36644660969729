export default {
  data() {
    return {
      //关于我们
      aboutUsIndex: 0,
      aboutUs: {
        titles: ['企业介绍', '发展历程', '荣誉资质'],
        pictures: [this.GLOBAL.OSS_BASE_URL + '/about-us/navgywm_qyjs.jpg', this.GLOBAL.OSS_BASE_URL + '/about-us/navgywm_qyjs.jpg', this.GLOBAL.OSS_BASE_URL + '/about-us/navgywm_qyjs.jpg'],
        contents: ['翼界科技依托科技创新与跨领域合作，为自然保护地提供创新型和精细化管理解决方案。在北海、南宁、海口设有办公室，办公场地和研发中心面积约1000余平方米，另拥有条件良好的无人机测试验证基地和民用无人机驾驶执照培训基地。公司拥有多旋翼无人机、固定翼无人机、复合翼无人机等各种类无人机载机平台，拥有完善的软硬件研发和测试设备。与广西科学院联合成立广西科学院生物多样性保护与无人机应用研究中心。', '2017年1月成立广西翼界科技有限公司，注册于中国电子北部湾信息产业园; <br /><br />2022年6月成立广西科学院生物多样性保护与无人机应用研究中心<br /><br />', '翼界科技已被认定为科技型中小企业、国家高新技术企业（证书编号：GR202145001224）、软件企业和“守合同重信用”企业等，并获民航局颁发的《民用无人驾驶航空器经营许可证》（编号：民航通（无）企字第001755号）。<br /><br />']
      }
    };
  },
  methods: {
    titleHover(e, type) {
      this[type] = e;
    },
    close() {
      this.$emit('close');
    }
  }
};