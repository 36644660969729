import "core-js/modules/es.array.push.js";
export default {
  data() {
    return {
      routePath: ['scene-video', 'product-composite', 'uav-services', 'serve-cultivate'],
      footerList: [{
        name: '无人机',
        list: ['应用场景', '产品设备', '无人机服务', '配套服务']
      }, {
        name: '自然教育',
        list: ['内容策划', '场域打造', ' 平台支撑']
      }, {
        name: '信息化',
        list: ['综合管理系统', '翼查记', '大屏可视化', '自然脉搏平台']
      }],
      contactUs: {
        name: '联系我们'
      }
    };
  },
  methods: {
    handlRouterLink(index, subindex) {
      console.log(index, subindex);
      if (index == 1) {
        let data = {
          menuIndex: subindex,
          subMenuIndex: 0
        };
        if (subindex == 0) {
          this.$router.push({
            name: 'explain',
            params: data,
            query: data
          });
          return;
        }
        this.$router.push({
          name: 'survey',
          query: data
        });
        return;
      } else if (index == 0) {
        this.$router.push({
          name: this.routePath[subindex]
        });
        return;
      } else if (index == 2) {
        let data = {
          menuIndex: 2,
          subMenuIndex: 2
        };
        if (subindex == 3) {
          this.$router.push({
            name: 'survey',
            params: data,
            query: data
          });
          return;
        }
      }
    }
  }
};