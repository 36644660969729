var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('Header', {
    on: {
      "setMenuIndex": _vm.getMenuIndex,
      "navFlag": _vm.getNavFlag
    }
  }), _c('router-view', {
    key: _vm.$route.fullPath
  }), _c('BtFence')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };