export default {
  name: 'DroneNav',
  data() {
    return {
      subMenuIndex: 0,
      produceIndex: 0,
      //产品设备
      applyScenIndex: 0,
      //应用场景index
      supportServiceIndex: 0,
      //配套服务index
      applicationExampleIndex: 0,
      //应用案例index
      subMenuList: ['应用场景', '产品设备', '无人机服务', '配套服务', '应用案例'],
      routePath: ['uav-services'],
      //应用场景
      applyScen: {
        titles: ['视频巡检', '遥感监测', '数据中继'],
        routePath: ['scene-video', 'scene-remotesensing', 'scene-relay'],
        pictures: [this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_yy_sp.jpg', this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_yy_yg.jpg', this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_yy_sjzj.jpg'],
        contents: ['无人机从空中俯视的视角，具有大纵深、宽视角的特点，解决了低视角的视线阻挡问题，并且无人机可以快速飞达目的地。无人机搭载吊舱设备、结合合理的飞行路线设置，可以监看到自然保护地的每个角落。无人机拍摄到的视频和照片，可以回传到地面端，实时查看现场视频信息，并通过直播系统实现远程直播。', '结合自主开发的POS数据采集管理技术，无人机飞行平台通过搭载相机终端对地连续性的自动拍摄，实现数据与地理定位的闭环，结合新型倾斜摄影测量技术、贴近摄影测量技术，获得带有空间信息的各类影像、地图和实景模型，实现全面的精细观察、地形描绘、地物变化标识、物种分布等信息和数据，为保护管理提供可靠依据。', '无人机巡检系统可以搭载数据中继传输设备，构建数据传输网络，收集自然保护地布设的红外感应相机、动物跟踪器、地面环境监测传感器、监控视频、护林员巡护移动设备等监测传感数据。']
      },
      produceList: [[{
        picture: this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_cp_wrj01.png',
        title: '复合翼无人机',
        childs: [{
          routePath: 'product-composite',
          img: this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_cp_wrj.png',
          title: '犀鸟Ⅱ复合翼无人机'
        }]
      }, {
        picture: this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_cp_zh.png',
        title: '任务载荷',
        childs: [{
          routePath: 'product-missionpayload',
          img: this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_cp_zh01.png',
          title: '双光视频吊舱'
        }, {
          routePath: 'product-missionpayload',
          img: this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_cp_zh02.png',
          title: '倾斜相机吊舱'
        }, {
          routePath: 'product-missionpayload',
          img: this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_cp_zh03.png',
          title: '多光谱吊舱'
        }]
      }, {
        picture: this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_cp_dmz.png',
        title: '妥空地面站',
        childs: [{
          routePath: 'product-tuokong',
          img: this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_cp_dmz01.png',
          title: '妥空地面站'
        }]
      }, {
        picture: this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_cp_wg.png',
        title: '免维护智能网关',
        childs: [{
          routePath: 'product-smartgateway',
          img: this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_cp_wg01.png',
          title: '智能网关'
        }]
      }], [{
        picture: this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_cp_glpt01.png',
        title: '站点无人机自动起降管理平台',
        childs: [{
          routePath: 'product-groundstation',
          img: this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_cp_glpt.png',
          title: '站点无人机自动起降管理平台'
        }]
      }]],
      //配套服务
      supportService: {
        titles: ['持证培训', '空域申请和设备部署', '无人机设备保养', '厂商合作'],
        routePath: ['serve-cultivate', 'serve-deployment', 'serve-maintain', 'serve-manufacturer'],
        pictures: [this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_pt_cz.jpg', this.GLOBAL.OSS_BASE_URL + '/UAV/wrjxq_supportingservice_ks1.jpg', this.GLOBAL.OSS_BASE_URL + '/UAV/Snipaste_2023-04-10_10-33-39.jpg', this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_pt_cs.jpg'],
        contents: ['翼界科技为自然保护地管理人员提供中国民航局(CAAC)授权的无人机培训。翼界科技无人机培训基地位于广西南宁九龙生态园，是一所拥有食、宿、学一体化及合法空域的无人机培训基地。基地坚持“安全高效、学以致用”的原则，为用户提供全方位服务，对学员进行理论、实操的全面培训。基地培训机型涵盖固定翼、多旋翼、垂直起降固定翼无人机。', '根据国家民航总局发布的《民用无人驾驶航空器实名制登记管理规定》， 如果使用无人机进行航拍、正摄与倾斜等空中作业，还需要申请空域的。翼界科技拥有成熟的经验和专业的渠道，申请靠谱规范。', '翼界科技针对无人机等设备提供完善的设备保养服务，通过技术培训、返厂维护等方式，对客户的无人机日常使用做针对性的设备保养，延长无人机使用生命周期。', '翼界科技与相关行业企事业单位保持着紧密的合作关系，在产品及业务上有广泛的专研探讨。翼界科技与大疆科技、易安卫士、灵感生态等多家科技厂商有良好的合作关系，在搭建空地协同的无人机自然保护地巡检解决方案等方面有深度合作。']
      },
      //应用案例
      applicationExample: {
        titles: ['野生动物调查', '野生动物栖息地监测案例', '自然保护地威胁监测提取', '森林防火巡检', '自然保护地工程项目建设研判'],
        routePath: ['case-survey', 'case-monitoring', 'case-extract', 'case-inspection', 'case-protectedarea'],
        pictures: [this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_yy_dc.jpg', this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_yy_jc.jpg', this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_yy_tq.jpg', this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_yy_xj.jpg', this.GLOBAL.OSS_BASE_URL + '/UAV/navwrj_yy_yp.jpg'],
        contents: ['无人机的远程感知能力提供了一种侵入性更小、无损伤、可重复的和可靠的监测技术，从而收集物种数量和分布，记录野生动物行为、生活史和健康状况。', '基于无人机的航拍影像，可以构建自然保护地和野生动植物栖息地的实景三维模型，从而为了解和测量野生动物栖息地提供了绝佳的窗口。', '利用无人机对自然保护地范围内的人为活动干扰进行巡检监测，制作正射影像底图，构建自然保护地全域的生境本底现状。', '选择合适的无人机平台配备热成像和可见光吊舱，在距地面800m到3000m飞行高度对地面火场进行实时观察，将获取的图像数据实时传回地面。为自然保护地管理机构和管理部门第一时间提供火场地理坐标(经纬度)、火场面积、火场边界、火场火头蔓延趋势等火场态势情报。', '通过自然保护地三维模型，叠加工程设计方制作的 BIM模型可以对拟建工程项目对自然保护地的影响做出真实有效的前瞻性评估。']
      }
    };
  },
  methods: {
    titleHover(e, type) {
      this[type] = e;
    },
    close() {
      this.$emit('close');
    }
  }
};