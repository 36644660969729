import "core-js/modules/es.array.push.js";
export default {
  name: 'NatureNav',
  data() {
    return {
      subMenuIndex: 0,
      contentPlanningIndex: 0,
      // 内容策划Index
      siteBuildingIndex: 0,
      //场域打造Index
      technicalSupportIndex: 0,
      //技术支撑
      subTitleIndex: 0,
      // 侧边栏二级标题
      subMenuList: ['内容策划', '场域打造', '平台支撑'],
      //内容策划
      contentPlanning: [{
        title: '解说体系',
        imgList: [{
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_ch_jsty01.png',
          txt: '方法论'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_ch_jsty02.png',
          txt: '印刷品'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_ch_jsty03@2x.jpg',
          txt: '视频'
        }],
        children: [],
        content: '解说体系是构建自然保护地自然教育体系的核心基础，是自然保护地总体内容与特色资源对外展示的窗口。解说体系遵循科学性、互动性、易懂性的设计原则，通过印刷品、展板、数字媒体等多种形式呈现在公众面前。'
      }, {
        title: '课程体系',
        imgList: [{
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_ch_kctx01.jpg',
          txt: '课程设计指南'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_ch_kctx02.png',
          txt: '教师手册'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/gxbhbh_zrjy_gcsc.png',
          txt: '营期手册'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_ch_kctx04.jpg',
          txt: '讲座课程'
        }],
        children: [],
        content: '课程体系以亲子家庭和中小学生为受众，以自然保护地资源为元素，在全面了解自然保护地资源特色及科普需求的基础上，对自然课程进行本土化、系统化设计。课程体系以提升公众对自然认知及乡土认同感为宗旨，引导公众走进自然，体验自然，提高公众责任担当、学会学习、健康生活、实践创新等方面的意识和能力。'
      }, {
        title: '活动体系',
        imgList: [],
        children: [{
          title: '在地自然教育活动',
          imgList: [{
            src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_ch_hdtx_jy01.jpg',
            txt: '研学营期'
          }, {
            src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_ch_hdtx_jy02.jpg',
            txt: '进校园活动'
          }, {
            src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_ch_hdtx_jy03.jpg',
            txt: '科普大讲堂'
          }, {
            src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_ch_hdtx_jy04.jpg',
            txt: '云上自然课堂'
          }],
          content: ''
        }, {
          title: '自然观察节',
          imgList: [{
            src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_ch_hdtx_gcj01.jpg',
            txt: ''
          }],
          content: '自然观察节是公众参与科学研究，深入了解自然保护地的一种竞赛活动，可以为公众提供观察自然、亲近自然的机会。公众通过观察和记录不同物种的种类、分布、数量等信息，为自然保护地生物多样性研究和保护提供基础数据，还可以通过微信、微博和短视频平台等新媒体对自然观察节进行传播。'
        }, {
          title: '嘉年华',
          imgList: [{
            src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_ch_hdtx_jnh01.jpg',
            txt: ''
          }],
          content: '自然嘉年华是以自然元素为主题的集市活动，它带来大量的自然教育机构的参与，通过嘉年华让更多公众在体验中留下一颗自然的种子，从而进入自然、了解自然、进而保护自然。自然嘉年华能够广泛扩大保护地在当地县域的影响力，新颖有趣，活动周期长，覆盖人群广。'
        }],
        content: ''
      }],
      //场域打造
      siteBuilding: [{
        title: '标识标牌',
        imgList: [{
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_cy_bs01.jpg',
          txt: ''
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_cy_bs02.jpg',
          txt: ''
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_cy_bs03.jpg',
          txt: ''
        }],
        children: [],
        content: '标识标牌系统是保护地开展宣教工作最基本也是最重要的设施，反映和体现保护地的宣教特色、管理水平、服务水平和专业水平。'
      }, {
        title: '宣教驿站',
        imgList: [{
          src: this.GLOBAL.OSS_BASE_URL + '/nature/xjyz_ztxg1.png',
          txt: ''
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/xjyz_ztxg2.png',
          txt: ''
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/xjyz_ztxg3.png',
          txt: ''
        }],
        children: [],
        content: '“驿站”作为中国传统文化的延续，经过时代的演变，已经进一步与现代生活需求进行了结合升级，许多旅游度假胜地都采用了集装箱建筑模式打造具有当地特色文化的“旅游驿站”，其成为承载了服务游客、员工办公等重要活动场地，因此建议保护地的生态旅游资源采用“现代化驿站”的概念进行建造。考虑到现场建筑施工等问题，宣教驿站将采用集装箱建筑模式。集装箱建筑模式在现代已经非常成熟，其造型的多变性，可使自然爱好者人群聚集，形成区域性质的网红打卡点。'
      }, {
        title: '自然中心',
        imgList: [{
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_cy_zx01.jpg',
          txt: '科技大屏'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_cy_zx02.jpg',
          txt: '正品标本'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_cy_zx03.jpg',
          txt: '专题展陈'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_cy_zx04.jpg',
          txt: '生态塘还原'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_cy_zx05.jpg',
          txt: '户外生境改造'
        }],
        children: [],
        content: ''
      }],
      //技术支撑
      technicalSupport: [{
        title: '实地调研',
        imgList: [{
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_js_dy01.jpg',
          txt: '资源调查'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_js_dy02.jpg',
          txt: '社区调查'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_js_dy03.jpg',
          txt: '素材采风'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_js_dy04.jpg',
          txt: '总结报告'
        }],
        children: [],
        content: '了解保护地周边社区自然资源和人文资源条件，获取社区社会经济现状和社区参与自然教育为导向的意愿'
      }, {
        title: '陪伴式能力建设',
        imgList: [{
          src: this.GLOBAL.OSS_BASE_URL + '/nature/Snipaste_2023-04-10_10-31-32.jpg',
          txt: '参与式培训'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_js_jc02.jpg',
          txt: '新媒体培训'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_js_jc03.jpg',
          txt: '社区培训'
        }],
        children: [],
        content: '以自然教育为方向，以社区为实施阵地，提升社区村民的自然导赏能力和服务接待能力，使得社区有能力接待相关旅游、自然教育及研学相关活动。'
      }, {
        title: '自然脉搏平台',
        imgList: [{
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_js_pt_new.jpg',
          txt: ''
        }],
        children: [],
        content: '自然保护地综合的公众服务平台，实现自然保护地线上导览，自然教育活动管理等功能的综合型自然教育系统。从自然教育的四种形式——在线导览、自然观察、自然教育活动、自然向导提供技术支撑，满足社区、活动组织者、资深爱好者、公众等关键性利益相关者的需求。'
      }, {
        title: '深耕团队',
        imgList: [{
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_js_td01@2x.jpg',
          txt: '覃世赢'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_js_td02@2x.jpg',
          txt: '余寿毅'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_js_td03@2x.jpg',
          txt: '肖晓波'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_js_td04@2x.jpg',
          txt: '宁浩洋'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_js_td05@2x.jpg',
          txt: '黄炜'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_js_td06@2x.jpg',
          txt: '何洁冰'
        }, {
          src: this.GLOBAL.OSS_BASE_URL + '/nature/navjy_js_td07@2x.jpg',
          txt: '何宗泉'
        }],
        children: [],
        content: '项目团队从2011年起，先后在云南、海南、广西、青海、四川等多地实施过“华盖木环境教育进校园”、“海南长臂猿社区自豪项目”、“广西渠楠白头叶猴保护小区自然教育基地建设项目”、“广西大明山国家级自然保护区自然教育能力提升项目”、“四川攀枝花国家级自然保护区通过自然教育路径开展社区共建”、“青海国家级自然保护区自然教育课程体系建设”等项目，在自然教育领域积累了丰富的经验。并在2020-2022年深入广西自然保护地，打造了以广西大新黑水河国家湿地公园、广西北海滨海国家湿地公园、广西恩城国家级自然保护区为示范点的自然保护地自然教育体系建设经典案例。'
      }]
    };
  },
  computed: {
    // 侧边栏二级title是否显示
    isSubTitleShow() {
      return this.contentPlanning[this.contentPlanningIndex].children.length > 0;
    },
    getPicData() {
      var _this$contentPlanning, _this$contentPlanning2;
      let imgList = this.contentPlanning[this.contentPlanningIndex].imgList;
      return imgList.length > 0 ? imgList : (_this$contentPlanning = this.contentPlanning[this.contentPlanningIndex]) === null || _this$contentPlanning === void 0 ? void 0 : (_this$contentPlanning2 = _this$contentPlanning.children[this.subTitleIndex]) === null || _this$contentPlanning2 === void 0 ? void 0 : _this$contentPlanning2.imgList;
    },
    getContentData() {
      return this.contentPlanning[this.contentPlanningIndex].content || this.contentPlanning[this.contentPlanningIndex].children[this.subTitleIndex].content;
    }
  },
  methods: {
    hanldMore() {
      let data = {
        menuIndex: this.subMenuIndex,
        subMenuIndex: 0
      };
      if (this.subMenuIndex == 0) {
        data.subMenuIndex = this.contentPlanningIndex;
        data.natureSubIndex = this.subTitleIndex; //natureSubIndex 活动体系子菜单index
      } else if (this.subMenuIndex == 1) {
        data.subMenuIndex = this.siteBuildingIndex;
      } else if (this.subMenuIndex == 2) {
        data.subMenuIndex = this.technicalSupportIndex;
      }
      this.$emit('setNatureMenuIndexs', data);
      this.$emit('close');
      if (this.subMenuIndex == 0 && data.subMenuIndex != 2) {
        this.$router.push({
          name: 'explain',
          query: data
        });
        return;
      }
      this.$router.push({
        name: 'survey',
        query: data
      });
    },
    titleHover(e, type) {
      this[type] = e;
    },
    // 侧边栏title是否显示 > icon
    isArrowRightIconShow(index) {
      var _this$contentPlanning3;
      return this.contentPlanningIndex === index && ((_this$contentPlanning3 = this.contentPlanning[index]) === null || _this$contentPlanning3 === void 0 ? void 0 : _this$contentPlanning3.children.length) > 0;
    },
    close() {
      this.$emit('close');
    }
  }
};