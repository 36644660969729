/*
 * @Description:
 * @Version: 2.0
 * @Autor: wangmiao
 * @Date: 2023-03-13 18:24:20
 * @LastEditors: wangmiao
 * @LastEditTime: 2023-04-10 15:02:56
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '首页',
      direct: true,
    },
    component: () => import('@/views/HomePage'),
  },
  // 无人机导航路由
  {
    path: '/drone',
    name: 'drone',
    meta: {
      title: '无人机',
      direct: false,
    },
    component: () => import('@/views/DroneView'),
    redirect: '/drone/apply-scen',
    children: [
      //应用场景
      {
        path: 'apply-scen',
        component: () => import('@/views/droneDetails/apply-scen'),
        redirect: { name: 'scene-video' },
        children: [
          {
            path: 'scene-video',
            name: 'scene-video',
            component: () => import('@/views/droneDetails/scene-video'),
          },
          {
            path: 'scene-remotesensing',
            name: 'scene-remotesensing',
            component: () => import('@/views/droneDetails/scene-remotesensing'),
          },
          {
            path: 'scene-relay',
            name: 'scene-relay',
            component: () => import('@/views/droneDetails/scene-relay'),
          },
        ],
      },
      //产品设备
      {
        path: 'product-equipment',
        component: () => import('@/views/droneDetails/product-equipment'),
        redirect: { name: 'product-composite' },
        children: [
          {
            path: 'product-composite',
            name: 'product-composite',
            component: () => import('@/views/droneDetails/product-composite'),
          },
          {
            path: 'product-missionpayload',
            name: 'product-missionpayload',
            component: () => import('@/views/droneDetails/product-missionpayload'),
          },
          {
            path: 'product-tuokong',
            name: 'product-tuokong',
            component: () => import('@/views/droneDetails/product-tuokong'),
          },
          {
            path: 'product-smartgateway',
            name: 'product-smartgateway',
            component: () => import('@/views/droneDetails/product-smartgateway'),
          },
          {
            path: 'product-groundstation',
            name: 'product-groundstation',
            component: () => import('@/views/droneDetails/product-groundstation'),
          },
        ],
      },
      //无人机服务
      {
        path: 'uav-servepage',
        component: () => import('@/views/droneDetails/uav-servepage'),
        redirect: { name: 'uav-services' },
        children: [
          {
            path: 'uav-services',
            name: 'uav-services',
            component: () => import('@/views/droneDetails/uav-services'),
          },
        ],
      },
      // 配套服务路由
      {
        path: 'supporting-serve',
        component: () => import('@/views/droneDetails/supporting-serve'),
        redirect: { name: 'serve-cultivate' },
        children: [
          {
            path: 'serve-cultivate',
            name: 'serve-cultivate',
            component: () => import('@/views/droneDetails/serve-cultivate'),
          },
          {
            path: 'serve-deployment',
            name: 'serve-deployment',
            component: () => import('@/views/droneDetails/serve-deployment'),
          },
          {
            path: 'serve-maintain',
            name: 'serve-maintain',
            component: () => import('@/views/droneDetails/serve-maintain'),
          },
          {
            path: 'serve-manufacturer',
            name: 'serve-manufacturer',
            component: () => import('@/views/droneDetails/serve-manufacturer'),
          },
        ],
      },
      //应用案例
      {
        path: 'application-case',
        component: () => import('@/views/droneDetails/application-case'),
        redirect: { name: 'case-survey' },
        children: [
          {
            path: 'case-survey',
            name: 'case-survey',
            component: () => import('@/views/droneDetails/case-survey'),
          },
          {
            path: 'case-monitoring',
            name: 'case-monitoring',
            component: () => import('@/views/droneDetails/case-monitoring'),
          },
          {
            path: 'case-extract',
            name: 'case-extract',
            component: () => import('@/views/droneDetails/case-extract'),
          },
          {
            path: 'case-inspection',
            name: 'case-inspection',
            component: () => import('@/views/droneDetails/case-inspection'),
          },
          {
            path: 'case-protectedarea',
            name: 'case-protectedarea',
            component: () => import('@/views/droneDetails/case-protectedarea.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/nature',
    name: 'nature',
    component: () => import('@/views/nature/index'),
    hidden: true,
    meta: {
      title: '自然教育',
      direct: false,
    },
    children: [
      {
        path: 'survey',
        name: 'survey',
        component: () => import('@/views/nature/nature-survey'),
      },
      {
        path: 'explain',
        name: 'explain',
        component: () => import('@/views/nature/nature-explain'),
      },
    ],
  },
  // 信息化
  {
    path: '/info',
    name: 'info',
    meta: {
      title: '信息化',
      direct: true,
    },
    component: () => import('@/views/InfoView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '关于我们',
      direct: false,
    },
    component: () => import('@/views/AboutView'),
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    meta: {
      title: '联系我们',
      direct: true,
    },
    component: () => import('@/views/ContactUs'),
  },
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// 添加页面加载进度条
router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})
router.afterEach(() => {
  NProgress.done()
})

export default router
