import "core-js/modules/es.array.push.js";
// @ is an alias to /src
import Header from '@/components/header/main.vue';
// import HomePage from './HomePage.vue'
import BtFence from '@/components/footer-var.vue';
// import NatureView from './NatureView.vue'
export default {
  name: 'HomeView',
  components: {
    Header,
    // HomePage,
    BtFence
    // NatureView,
  },

  data() {
    return {
      natureIndex: {},
      navFlag: 0
    };
  },
  methods: {
    getMenuIndex(data) {
      this.natureIndex = data;
      if (this.navFlag == 2) {
        // this.$router.push({
        //   name: 'nature',
        //   params: data,
        //   query: data,
        // })
        // return
      } else if (this.navFlag == 1) {
        this.$router.push({
          name: 'drone',
          params: data,
          query: data
        });
        return;
      } else {
        this.$router.go('/');
      }
    },
    getNavFlag(e) {
      this.navFlag = e;
    }
  }
};