var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "submenu"
  }, [_c('div', {
    staticClass: "submenu__section about-us flex"
  }, [_c('ul', {
    staticClass: "about-us__title"
  }, _vm._l(_vm.aboutUs.titles, function (item, tIdx) {
    return _c('li', {
      key: item,
      class: {
        'submenu__title--hover': _vm.aboutUsIndex === tIdx
      }
    }, [_c('span', {
      on: {
        "mouseover": function ($event) {
          return _vm.titleHover(tIdx, 'aboutUsIndex');
        }
      }
    }, [_vm._v(_vm._s(item))])]);
  }), 0), _c('div', {
    staticClass: "about-us__pic"
  }, [_c('img', {
    attrs: {
      "src": _vm.aboutUs.pictures[_vm.aboutUsIndex],
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "about-us__content"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.aboutUs.contents[_vm.aboutUsIndex])
    }
  }), _c('router-link', {
    staticClass: "more block",
    attrs: {
      "to": {
        path: '/about',
        query: {
          index: _vm.aboutUsIndex
        }
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_vm._v(" 了解更多 > ")])], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };