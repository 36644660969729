/*
 * @Description:
 * @Version: 2.0
 * @Autor: wangmiao
 * @Date: 2023-03-13 18:24:20
 * @LastEditors: wangmiao
 * @LastEditTime: 2023-03-28 18:54:45
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'assets/css/tailwindcss.css'

import global from './common/common'
import BaiduMap from 'vue-baidu-map'

Vue.prototype.GLOBAL = global

Vue.config.productionTip = false
Vue.use(BaiduMap, {
  ak: 'rQxHPQX1ua21rWnXzTbUh5KSSUbnefxR',
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
