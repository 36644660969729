var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "footer"
  }, [_c('div', {
    staticClass: "footer-block text-left"
  }, [_c('el-row', [_vm._l(_vm.footerList, function (item, footerIndex) {
    return _c('el-col', {
      key: footerIndex,
      attrs: {
        "span": 4
      }
    }, [_c('div', {
      staticClass: "font-semibold text-lg mb-7"
    }, [_vm._v(_vm._s(item.name))]), _vm._l(item.list, function (chidl, chidlIdnex) {
      return _c('div', {
        key: chidlIdnex,
        staticClass: "chidl-text cursor-pointer mb-7 text-sm",
        on: {
          "click": function ($event) {
            return _vm.handlRouterLink(footerIndex, chidlIdnex);
          }
        }
      }, [_vm._v(" " + _vm._s(chidl) + " ")]);
    })], 2);
  }), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "font-semibold text-lg mb-7"
  }, [_vm._v(_vm._s(_vm.contactUs.name))]), _c('div', {
    staticClass: "text mb-7 text-sm"
  }, [_vm._v("电话:17677132233")]), _c('div', {
    staticClass: "text mb-7 text-sm"
  }, [_vm._v("邮箱:office@ejdrone.com")]), _c('div', {
    staticClass: "text mb-7 text-sm"
  }, [_vm._v("南宁办:广西南宁市青秀区金浦路16号汇东国际F座6楼")]), _c('div', {
    staticClass: "text mb-7 text-sm"
  }, [_vm._v("北海办:广西北海市工业园区中国电子北部湾信息港B塔楼201")]), _c('div', {
    staticClass: "text mb-7 text-sm"
  }, [_vm._v(" 海口办：海南省海口市国家高新技术产业开发区狮子岭工业园光伏北路18号研发办公楼4层 ")])]), _c('el-col', {
    attrs: {
      "span": 4
    }
  }, [_c('div', {
    staticClass: "qrcode float-right"
  }, [_c('img', {
    staticClass: "w-full",
    attrs: {
      "src": "https://media.ejdrone.com/icon/yj_wexin_gzh_pic.png",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "text-center mt-4 text"
  }, [_vm._v("关于我们")])])])], 2), _vm._m(0)], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "footer-bottom w-full text-center text"
  }, [_c('div', {
    staticClass: "mb-4 pt-4 text-sm chidl-text"
  }, [_c('a', {
    attrs: {
      "href": "https://beian.miit.gov.cn/"
    }
  }, [_vm._v("备案号：桂ICP备19000821号-1 © 翼界科技 2017-2023")])])]);
}];
export { render, staticRenderFns };