var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "header-wrap",
    on: {
      "mouseleave": function ($event) {
        return _vm.headerHover();
      },
      "mouseenter": function ($event) {
        return _vm.headerHover();
      }
    }
  }, [_c('div', {
    class: ['top', {
      'top--hover': _vm.navbarHighlight || _vm.isEnter
    }]
  }, [_c('div', {
    ref: "navbar",
    staticClass: "header flex items-center mx-auto justify-between"
  }, [_c('h1', [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "id": "logo",
      "src": _vm.logoSrc,
      "alt": "翼界科技"
    }
  })]), _c('strong', {
    staticClass: "hidden"
  }, [_vm._v("翼界科技")])], 1), _c('ul', {
    staticClass: "navbar flex"
  }, _vm._l(_vm.mainMenu, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "navbar__item",
      class: {
        'navbar__item--hover': _vm.navFlag === index
      },
      on: {
        "mouseover": function ($event) {
          return _vm.mouseOver(index);
        }
      }
    }, [_c('router-link', {
      attrs: {
        "to": item.meta.direct ? item.path : ''
      }
    }, [_vm._v(" " + _vm._s(item.meta.title) + " ")])], 1);
  }), 0)])]), _vm.isEnter ? _c('div', {
    staticClass: "header-inner bg-white"
  }, [_vm.navFlag == 1 ? _c('DroneNav', {
    on: {
      "close": _vm.onClose
    }
  }) : _vm._e(), _vm.navFlag == 2 ? _c('NatureNav', {
    on: {
      "close": _vm.onClose,
      "setNatureMenuIndexs": _vm.getNatureMenuIndexs
    }
  }) : _vm._e(), _vm.navFlag == 4 ? _c('AboutUsNav', {
    on: {
      "close": _vm.onClose
    }
  }) : _vm._e()], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };